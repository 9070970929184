import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";
import { MaybeRef } from "vue";
import type { ListServicesQueryVariables } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listServicesQuery = graphql(/* GraphQL */ `
  query listServices(
    $where: services_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [services_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = true
  ) {
    services(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      name @include(if: $includeName)
    }
    services_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export type ListServicesIncludesValue =
  IncludesProp<ListServicesQueryVariables>;

export interface ListServicesVariables {
  limit?: MaybeRef<ListServicesQueryVariables["limit"]>;
  offset?: MaybeRef<ListServicesQueryVariables["offset"]>;
  order_by?: MaybeRef<ListServicesQueryVariables["order_by"]>;
  where?: MaybeRef<ListServicesQueryVariables["where"]>;
  includes: MaybeRef<ListServicesIncludesValue>;
}

export function useListServices(props: ListServicesVariables) {
  return useQuery({
    query: listServicesQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListServicesQueryVariables,
  });
}

export async function listServices(props: ListServicesVariables) {
  const response = await client
    .query(listServicesQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListServicesQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.services;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getServiceQuery = graphql(/* GraphQL */ `
  query getService($id: uuid!) {
    services_by_pk(id: $id) {
      id
      name
    }
  }
`);

export async function getService(id: string) {
  const response = await client.query(getServiceQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.services_by_pk;
  return data;
}

export const getServicesQuery = graphql(/* GraphQL */ `
  query getServices {
    services {
      id
      name
    }
  }
`);

export function useGetServices() {
  return useQuery({
    query: getServicesQuery,
  });
}

export async function getServices() {
  const response = await client.query(getServicesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.services;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createServiceMutation = graphql(/* GraphQL */ `
  mutation createService($name: String!) {
    insert_services_one(object: { name: $name }) {
      id
      name
    }
  }
`);

export async function createService(name: string) {
  const response = await client
    .mutation(createServiceMutation, { name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.insert_services_one;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const updateServiceMutation = graphql(/* GraphQL */ `
  mutation updateService($id: uuid!, $name: String!) {
    update_services_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`);

export async function updateService(id: string, name: string) {
  const response = await client
    .mutation(updateServiceMutation, { id, name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_services_by_pk;
  if (!data) {
    throw new Error("Service not found");
  }

  return data;
}

export const deleteServiceMutation = graphql(/* GraphQL */ `
  mutation deleteService($id: uuid!) {
    delete_services_by_pk(id: $id) {
      id
      name
    }
  }
`);

export async function deleteService(id: string) {
  const response = await client
    .mutation(deleteServiceMutation, { id })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const data = response.data?.delete_services_by_pk;
  if (!data) {
    throw new Error("Service not found");
  }

  return data;
}
