import { graphql } from "../gql";
import { useQuery } from "@urql/vue";
import { client } from "..";

export const getScopesQuery = graphql(/* GraphQL */ `
  query getScopes {
    scopes {
      name
      description
    }
  }
`);

export function useGetScopes() {
  return useQuery({
    query: getScopesQuery,
  });
}

export async function getScopes() {
  const response = await client.query(getScopesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.scopes;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const searchScopesQuery = graphql(/* GraphQL */ `
  query searchScopes($search: String = "%") {
    scopes(where: { name: { _ilike: $search } }, limit: 25) {
      name
      description
    }
  }
`);

export async function searchScopes(search?: string) {
  const response = await client
    .query(searchScopesQuery, { search })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.scopes || [];
}
