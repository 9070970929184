import { client } from "..";
import { graphql } from "../gql";
import { OperationContext, RequestPolicy, useQuery } from "@urql/vue";
import {
  Audit_Audit_Trail_Order_By,
  GetInvoiceFeedQueryVariables,
} from "../types";
import { MaybeRef } from "vue";

export enum FeedTableName {
  Cost = "public.costs",
  Invoice = "public.invoices",
  Bill = "public.bills",
}

export const listAuditQuery = graphql(/* GraphQL */ `
  query getInvoiceFeed(
    $where: audit_audit_trail_bool_exp!
    $order_by: [audit_audit_trail_order_by!] = {}
  ) {
    rows: audit_audit_trail(where: $where, order_by: $order_by) {
      action
      changes
      event_id
      created_at
      user_id: hasura_user(path: "x-hasura-user-id")
      user_type: hasura_user(path: "x-hasura-role")
      row_data
      row_id
      table_name
    }
  }
`);

export type UseTableFeedProps = {
  variables: GetInvoiceFeedQueryVariables;
  pause?: MaybeRef<boolean>;
  context?: Partial<OperationContext>;
  requestPolicy?: RequestPolicy;
};

export function useTableFeed(params: UseTableFeedProps) {
  return useQuery({
    query: listAuditQuery,
    ...params,
  });
}

async function getTableFeed(
  id: number,
  table: FeedTableName,
  order_by?: Audit_Audit_Trail_Order_By
) {
  const response = await client
    .query(
      listAuditQuery,
      {
        where: {
          row_id: {
            _eq: id,
          },
          table_name: {
            _eq: table,
          },
        },
        order_by,
      },
      {
        requestPolicy: "cache-first",
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.rows;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export function getInvoiceFeed(id: number) {
  return getTableFeed(id, FeedTableName.Invoice);
}
export function getBillFeed(id: number) {
  return getTableFeed(id, FeedTableName.Bill);
}
export function getCostFeed(id: number) {
  return getTableFeed(id, FeedTableName.Cost);
}
