export default function parseBooleanEnv(
  variable: string | undefined
): boolean | undefined;
export default function parseBooleanEnv(
  variable: string | undefined,
  fallback: boolean
): boolean;
export default function parseBooleanEnv(
  variable: string | undefined,
  fallback?: boolean
): boolean | undefined {
  if (variable === "true") return true;
  else if (variable === "false") return false;
  else return fallback;
}
