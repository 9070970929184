import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type {
  CreateBillPaymentBulkMutationVariables,
  CreateBillPaymentMutationVariables,
  CreateInvoicePaymentBulkMutationVariables,
  CreateInvoicePaymentMutationVariables,
  ListPaymentsQueryVariables,
} from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listPaymentsQuery = graphql(/* GraphQL */ `
  query listPayments(
    $where: payments_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [payments_order_by!] = { payment_date: desc }
    $includeAmount: Boolean = false
    $includeAmountUsd: Boolean = false
    $includeCurrency: Boolean = false
    $includeId: Boolean = true
    $includePaymentDate: Boolean = false
    $includePaymentMethod: Boolean = false
  ) {
    payments_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    payments(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      amount @include(if: $includeAmount)
      amount_usd @include(if: $includeAmountUsd)
      currency @include(if: $includeCurrency)
      id @include(if: $includeId)
      payment_date @include(if: $includePaymentDate)
      payment_method @include(if: $includePaymentMethod)
    }
  }
`);

export type ListPaymentsIncludesValue =
  IncludesProp<ListPaymentsQueryVariables>;

export interface ListPaymentsVariables {
  limit?: MaybeRef<ListPaymentsQueryVariables["limit"]>;
  offset?: MaybeRef<ListPaymentsQueryVariables["offset"]>;
  order_by?: MaybeRef<ListPaymentsQueryVariables["order_by"]>;
  where?: MaybeRef<ListPaymentsQueryVariables["where"]>;
  includes: MaybeRef<ListPaymentsIncludesValue>;
}

export function useListPayments(props: ListPaymentsVariables) {
  return useQuery({
    query: listPaymentsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListPaymentsQueryVariables,
    context: {
      additionalTypenames: ["payments"],
    },
  });
}

export async function listPayments(props: ListPaymentsVariables) {
  const response = await client
    .query(
      listPaymentsQuery,
      {
        ...expandIncludes(props.includes),
        limit: props.limit,
        offset: props.offset,
        order_by: props.order_by,
        where: props.where,
        // Hack: have to override the type. See known issues in README
      } as ListPaymentsQueryVariables,
      {
        additionalTypenames: ["payments"],
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.payments;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createInvoicePaymentMutation = graphql(/* GraphQL */ `
  mutation createInvoicePayment(
    $invoice_id: Int!
    $amount: numeric!
    $currency: currencies_enum!
    $payment_date: timestamptz!
    $payment_method: payments_methods_enum!
  ) {
    insert_invoices_payments_one(
      object: {
        invoice_id: $invoice_id
        payment: {
          data: {
            amount: $amount
            currency: $currency
            payment_date: $payment_date
            payment_method: $payment_method
          }
        }
      }
    ) {
      payment {
        amount
        amount_usd
        currency
        id
        payment_date
        payment_method
      }
    }
  }
`);

export async function createInvoicePayment(
  data: CreateInvoicePaymentMutationVariables
) {
  const response = await client
    .mutation(createInvoicePaymentMutation, data, {
      additionalTypenames: ["payments_aggregate"],
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_invoices_payments_one?.payment;
  if (!result) {
    throw new Error("No data received");
  }
  return result;
}

export const createInvoicePaymentBulkMutation = graphql(/* GraphQL */ `
  mutation createInvoicePaymentBulk(
    $objects: [invoices_payments_insert_input!] = {}
  ) {
    insert_invoices_payments(objects: $objects) {
      returning {
        payment {
          amount
          amount_usd
          currency
          id
          payment_date
          payment_method
        }
      }
    }
  }
`);

export async function createInvoicePaymentBulk(
  data: CreateInvoicePaymentBulkMutationVariables
) {
  const response = await client
    .mutation(createInvoicePaymentBulkMutation, data, {
      additionalTypenames: ["payments_aggregate"],
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_invoices_payments?.returning;
  if (!result) {
    throw new Error("No data received");
  }
  return result;
}

export const createBillPaymentMutation = graphql(/* GraphQL */ `
  mutation createBillPayment(
    $bill_id: Int!
    $amount: numeric!
    $currency: currencies_enum!
    $payment_date: timestamptz!
    $payment_method: payments_methods_enum!
  ) {
    insert_bills_payments_one(
      object: {
        bill_id: $bill_id
        payment: {
          data: {
            amount: $amount
            currency: $currency
            payment_date: $payment_date
            payment_method: $payment_method
          }
        }
      }
    ) {
      payment {
        amount
        amount_usd
        currency
        id
        payment_date
        payment_method
      }
    }
  }
`);

export async function createBillPayment(
  data: CreateBillPaymentMutationVariables
) {
  const response = await client
    .mutation(createBillPaymentMutation, data, {
      additionalTypenames: ["payments_aggregate"],
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_bills_payments_one?.payment;
  if (!result) {
    throw new Error("No data received");
  }
  return result;
}

export const createBillPaymentBulkMutation = graphql(/* GraphQL */ `
  mutation createBillPaymentBulk(
    $objects: [bills_payments_insert_input!] = {}
  ) {
    insert_bills_payments(objects: $objects) {
      returning {
        payment {
          amount
          amount_usd
          currency
          id
          payment_date
          payment_method
        }
      }
    }
  }
`);

export async function createBillPaymentBulk(
  data: CreateBillPaymentBulkMutationVariables
) {
  const response = await client
    .mutation(createBillPaymentBulkMutation, data, {
      additionalTypenames: ["payments_aggregate"],
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_bills_payments?.returning;
  if (!result) {
    throw new Error("No data received");
  }
  return result;
}

export const deletePaymentMutation = graphql(/* GraphQL */ `
  mutation deletePayment($id: uuid!) {
    delete_payments_by_pk(id: $id) {
      id
    }
  }
`);

export async function deletePayment(id: string) {
  const response = await client
    .mutation(deletePaymentMutation, { id })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_payments_by_pk?.id;
  if (!result) {
    throw new Error("Payment not found");
  }
  return result;
}

export const deleteInvoicePaymentMutation = graphql(/* GraphQL */ `
  mutation deleteInvoicePayment($payment_id: uuid!) {
    delete_invoices_payments(where: { payment_id: { _eq: $payment_id } }) {
      affected_rows
    }
    delete_payments_by_pk(id: $payment_id) {
      id
    }
  }
`);

export async function deleteInvoicePayment(payment_id: string) {
  const response = await client
    .mutation(
      deleteInvoicePaymentMutation,
      { payment_id },
      {
        additionalTypenames: ["invoices"],
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_payments_by_pk?.id;
  if (!result) {
    throw new Error("Payment not found");
  }
  return result;
}

export const deleteBillPaymentMutation = graphql(/* GraphQL */ `
  mutation deleteBillPayment($payment_id: uuid!) {
    delete_bills_payments(where: { payment_id: { _eq: $payment_id } }) {
      affected_rows
    }
    delete_payments_by_pk(id: $payment_id) {
      id
    }
  }
`);

export async function deleteBillPayment(payment_id: string) {
  const response = await client
    .mutation(
      deleteBillPaymentMutation,
      { payment_id },
      {
        additionalTypenames: ["bills"],
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_payments_by_pk?.id;
  if (!result) {
    throw new Error("Payment not found");
  }
  return result;
}
