import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type {
  ListSuppliersQueryVariables,
  Supplieries_Insert_Input,
  Supplieries_Set_Input,
} from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listSuppliersQuery = graphql(/* GraphQL */ `
  query listSuppliers(
    $where: supplieries_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [supplieries_order_by!] = {}
    $includeId: Boolean = true
    $includeLegalName: Boolean = false
    $includeShortName: Boolean = false
    $includeCountry: Boolean = false
    $includeCreatedAt: Boolean = false
  ) {
    supplieries_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    supplieries(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      legal_name @include(if: $includeLegalName)
      short_name @include(if: $includeShortName)
      country @include(if: $includeCountry)
      created_at @include(if: $includeCreatedAt)
    }
  }
`);

export type ListSuppliersIncludesValue =
  IncludesProp<ListSuppliersQueryVariables>;

export interface ListSuppliersVariables {
  limit?: MaybeRef<ListSuppliersQueryVariables["limit"]>;
  offset?: MaybeRef<ListSuppliersQueryVariables["offset"]>;
  order_by?: MaybeRef<ListSuppliersQueryVariables["order_by"]>;
  where?: MaybeRef<ListSuppliersQueryVariables["where"]>;
  includes: MaybeRef<ListSuppliersIncludesValue>;
}

export function useListSuppliers(props: ListSuppliersVariables) {
  return useQuery({
    query: listSuppliersQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListSuppliersQueryVariables,
  });
}

export async function listSuppliers(props: ListSuppliersVariables) {
  const response = await client
    .query(listSuppliersQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListSuppliersQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.supplieries;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getSupplierQuery = graphql(/* GraphQL */ `
  query getSupplier($id: uuid!) {
    supplieries_by_pk(id: $id) {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export async function getSupplier(id: string) {
  const response = await client.query(getSupplierQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.supplieries_by_pk;
}

export const getSuppliersQuery = graphql(/* GraphQL */ `
  query getSuppliers {
    supplieries {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export function useGetSuppliers() {
  return useQuery({
    query: getSuppliersQuery,
  });
}

export async function getSuppliers() {
  const response = await client.query(getSuppliersQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.supplieries;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createSupplierMutation = graphql(/* GraphQL */ `
  mutation createSupplier($data: supplieries_insert_input!) {
    insert_supplieries_one(object: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function createSupplier(data: Supplieries_Insert_Input) {
  const response = await client
    .mutation(createSupplierMutation, {
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_supplieries_one;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const updateSupplierMutation = graphql(/* GraphQL */ `
  mutation updateSupplier($id: uuid!, $data: supplieries_set_input!) {
    update_supplieries_by_pk(pk_columns: { id: $id }, _set: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function updateSupplier(id: string, data: Supplieries_Set_Input) {
  const response = await client
    .mutation(updateSupplierMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.update_supplieries_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const deleteSupplierMutation = graphql(/* GraphQL */ `
  mutation deleteSupplier($id: uuid!) {
    delete_supplieries_by_pk(id: $id) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function deleteSupplier(id: string) {
  const response = await client
    .mutation(deleteSupplierMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_supplieries_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}
