import { exposeToConsole } from "@/utils/expose-to-console";
import getNamespacedKey from "@/utils/namespaced-key";
import mitt from "mitt";

export enum AuthTokenStoreStates {
  NotInitialized,
  HasTokens,
  NoTokens,
}

export type AuthTokenData = {
  accessToken: string;
  expiresAt?: number | null;
  refreshToken?: string | null;
};

export type AuthTokenStoreData = {
  state: AuthTokenStoreStates;
  targetStorage: Storage;
  accessToken: string | null;
  expiresAt: number | null;
  refreshToken: string | null;
};

const AccessTokenKey = getNamespacedKey("auth", "accessToken");
const AccessExpiresAtKey = getNamespacedKey("auth", "expiresAt");
const RefreshTokenKey = getNamespacedKey("auth", "refreshToken");

export type AuthTokenStoreEvents = {
  load: AuthTokenData;
  save: AuthTokenData;
  clear: void;
  stash: void;
  restore: void;
};
const emitter = mitt<AuthTokenStoreEvents>();

export const on = emitter.on;
export const off = emitter.off;

export let state = AuthTokenStoreStates.NotInitialized;
export let targetStorage = localStorage;
export let accessToken: AuthTokenStoreData["accessToken"] = null;
export let expiresAt: AuthTokenStoreData["expiresAt"] = null;
export let refreshToken: AuthTokenStoreData["refreshToken"] = null;
let storeStash: AuthTokenStoreData | null = null;

console.debug("[Auth Tokens] Loading tokens...");
if (localStorage.getItem(AccessTokenKey)) {
  targetStorage = localStorage;
  accessToken = localStorage.getItem(AccessTokenKey);
  expiresAt = asNumber(localStorage.getItem(AccessExpiresAtKey));
  refreshToken = localStorage.getItem(RefreshTokenKey);
  state = AuthTokenStoreStates.HasTokens;
  console.debug("[Auth Tokens] Loaded from localStorage.");
  emitter.emit("load", {
    accessToken: accessToken!,
    expiresAt: expiresAt,
    refreshToken,
  });
} else if (sessionStorage.getItem(AccessTokenKey)) {
  targetStorage = sessionStorage;
  accessToken = sessionStorage.getItem(AccessTokenKey);
  expiresAt = asNumber(localStorage.getItem(AccessExpiresAtKey));
  refreshToken = sessionStorage.getItem(RefreshTokenKey);
  state = AuthTokenStoreStates.HasTokens;
  console.debug("[Auth Tokens] Loaded from sessionStorage.");
  emitter.emit("load", {
    accessToken: accessToken!,
    expiresAt: expiresAt,
    refreshToken,
  });
} else {
  state = AuthTokenStoreStates.NoTokens;
  console.debug("[Auth Tokens] No tokens found.");
}

export function clear() {
  console.log("[Auth Tokens] Clear tokens.");
  accessToken = null;
  expiresAt = null;
  refreshToken = null;
  targetStorage.removeItem(AccessTokenKey);
  targetStorage.removeItem(AccessExpiresAtKey);
  targetStorage.removeItem(RefreshTokenKey);
  state = AuthTokenStoreStates.NoTokens;
  emitter.emit("clear");
}
/**
 * Stash and clear current store data.
 * It doesn't clear {@link targetStorage}.
 * The stashed data can be restored later by calling {@link restore}.
 */
export function stash() {
  console.log("[Auth Tokens] Stash store.");
  storeStash = {
    state,
    targetStorage,
    accessToken,
    expiresAt,
    refreshToken,
  };
  accessToken = null;
  expiresAt = null;
  refreshToken = null;
  state = AuthTokenStoreStates.NoTokens;
  emitter.emit("stash");
}
/**
 * Replace current store data with stashed data.
 * If stash is empty - does nothing.
 * It doesn't update {@link targetStorage}.
 * Use {@link stash} to stash store data.
 */
export function restore() {
  console.log("[Auth Tokens] Restore store.");
  if (storeStash) {
    state = storeStash.state;
    targetStorage = storeStash.targetStorage;
    accessToken = storeStash.accessToken;
    expiresAt = storeStash.expiresAt;
    refreshToken = storeStash.refreshToken;
    storeStash = null;
    state = AuthTokenStoreStates.HasTokens;
    emitter.emit("restore");
  }
}
export function save(
  access_token: string,
  expires_in?: number | null,
  refresh_token?: string | null
) {
  console.log("[Auth Tokens] Save tokens.");
  accessToken = access_token;
  expiresAt = expires_in ? Date.now() + expires_in * 1000 : null;
  refreshToken = refresh_token ?? null;
  targetStorage.setItem(AccessTokenKey, access_token);
  if (expiresAt) {
    targetStorage.setItem(AccessExpiresAtKey, expiresAt.toString());
  } else {
    targetStorage.removeItem(AccessExpiresAtKey);
  }
  if (refresh_token) {
    targetStorage.setItem(RefreshTokenKey, refresh_token);
  } else if (refresh_token === null) {
    targetStorage.removeItem(RefreshTokenKey);
  }
  state = AuthTokenStoreStates.HasTokens;
  emitter.emit("save", {
    accessToken: access_token,
    expiresAt: expiresAt,
    refreshToken: refresh_token,
  });
}

export function setStorage(storage: Storage) {
  console.log(
    `[Auth Tokens] Set target storage to ${
      storage == localStorage ? "local" : "session"
    }Storage.`
  );
  targetStorage = storage;
}

export function loadFromStorage(storage: Storage) {
  console.log(
    "[Auth Tokens] Load tokens from %s.",
    storage == localStorage ? "localStorage" : "sessionStorage"
  );
  targetStorage = storage;
  accessToken = targetStorage.getItem(AccessTokenKey);
  expiresAt = asNumber(targetStorage.getItem(AccessExpiresAtKey));
  refreshToken = targetStorage.getItem(RefreshTokenKey);
  if (accessToken) {
    state = AuthTokenStoreStates.HasTokens;
    emitter.emit("load", { accessToken, expiresAt: expiresAt, refreshToken });
  } else {
    state = AuthTokenStoreStates.NoTokens;
  }
}
function asNumber(value: string | null): number | null {
  return typeof value == "string" ? Number(value) : null;
}

exposeToConsole("authTokens", {
  clear,
  save,
  setStorage,
  loadFromStorage,
  toString() {
    return JSON.stringify({
      state,
      accessToken,
      expiresIn: expiresAt,
      refreshToken,
    });
  },
});
