import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";
import { MaybeRef } from "vue";
import type { ListCostsTypesQueryVariables } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listCostsTypesQuery = graphql(/* GraphQL */ `
  query listCostsTypes(
    $where: costs_types_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [costs_types_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = true
    $includeCreatedAt: Boolean = false
  ) {
    costs_types_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    costs_types(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      name @include(if: $includeName)
      created_at @include(if: $includeCreatedAt)
    }
  }
`);

export type ListCostsTypesIncludesValue =
  IncludesProp<ListCostsTypesQueryVariables>;

export interface ListCostsTypesVariables {
  limit?: MaybeRef<ListCostsTypesQueryVariables["limit"]>;
  offset?: MaybeRef<ListCostsTypesQueryVariables["offset"]>;
  order_by?: MaybeRef<ListCostsTypesQueryVariables["order_by"]>;
  where?: MaybeRef<ListCostsTypesQueryVariables["where"]>;
  includes: MaybeRef<ListCostsTypesIncludesValue>;
}

export function useListCostsTypes(props: ListCostsTypesVariables) {
  return useQuery({
    query: listCostsTypesQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListCostsTypesQueryVariables,
  });
}

export async function listCostsTypes(props: ListCostsTypesVariables) {
  const response = await client
    .query(listCostsTypesQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListCostsTypesQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs_types;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getCostsTypeQuery = graphql(/* GraphQL */ `
  query getCostsType($id: uuid!) {
    costs_types_by_pk(id: $id) {
      id
      name
    }
  }
`);

export async function getCostsType(id: string) {
  const response = await client.query(getCostsTypeQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs_types_by_pk;
  return data;
}

export const getCostsTypesQuery = graphql(/* GraphQL */ `
  query getCostsTypes {
    costs_types {
      id
      name
    }
  }
`);

export function useGetCostsTypes() {
  return useQuery({
    query: getCostsTypesQuery,
  });
}

export async function getCostsTypes() {
  const response = await client.query(getCostsTypesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs_types;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createCostTypeMutation = graphql(/* GraphQL */ `
  mutation createCostType($name: String!) {
    insert_costs_types_one(object: { name: $name }) {
      id
      name
    }
  }
`);

export async function createCostType(name: string) {
  const response = await client
    .mutation(createCostTypeMutation, { name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.insert_costs_types_one;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const updateCostTypeMutation = graphql(/* GraphQL */ `
  mutation updateCostType($id: uuid!, $name: String!) {
    update_costs_types_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`);

export async function updateCostType(id: string, name: string) {
  const response = await client
    .mutation(updateCostTypeMutation, { id, name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_costs_types_by_pk;
  if (!data) {
    throw new Error("Cost type not found");
  }

  return data;
}

export const deleteCostTypeMutation = graphql(/* GraphQL */ `
  mutation deleteCostType($id: uuid!) {
    delete_costs_types_by_pk(id: $id) {
      id
      name
      created_at
    }
  }
`);

export async function deleteCostType(id: string) {
  const response = await client
    .mutation(deleteCostTypeMutation, { id })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const data = response.data?.delete_costs_types_by_pk;
  if (!data) {
    throw new Error("Cost type not found");
  }

  return data;
}
