// This is main application config

import { exposeToConsole } from "@/utils/expose-to-console";
import parseBooleanEnv from "@/utils/parse-boolean";

const AppConfig = {
  MODE: import.meta.env.MODE,
  BASE_URL: import.meta.env.BASE_URL,
  PROD: import.meta.env.PROD,
  DEV: import.meta.env.DEV,
  SSR: import.meta.env.SSR,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  API_DOMAIN: import.meta.env.VITE_API_DOMAIN,
  API_URL_REST: import.meta.env.VITE_API_URL_REST,
  API_URL_FILE_STORAGE: import.meta.env.VITE_API_URL_FILE_STORAGE,
  API_URL_GRAPHQL: import.meta.env.VITE_API_URL_GRAPHQL,
  INCLUDE_TEST_GROUND: parseBooleanEnv(
    import.meta.env.VITE_INCLUDE_TEST_GROUND,
    import.meta.env.DEV
  ),
  SENTRY_ENABLE: parseBooleanEnv(
    import.meta.env.VITE_SENTRY_ENABLE,
    import.meta.env.PROD
  ),
  SENTRY_DNS: import.meta.env.VITE_SENTRY_DNS,
};

export default AppConfig;

exposeToConsole("getConfig", () => AppConfig);
