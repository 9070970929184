import { AxiosError } from "axios";
import http, { STORAGE_API_URL } from "./http";

export type StorageApiResponse<T extends Record<string, unknown> | null> = {
  error: boolean;
  message: string;
  data: T;
};

export type UploadPayload = {
  fileIds: string[];
};

export type FileInfoPayload = {
  name: string;
  /** Number string */
  size: number;
  /** MIME type */
  type: string;
  /** UUID of the user */
  uploadedBy: string;
  /** ISO timestamp */
  uploadedAt: string;
};

export async function checkStorageAccess() {
  const response = await http.get("/has-access", {
    validateStatus(status) {
      return [200, 401].includes(status);
    },
  });
  return response.status === 200;
}

export async function uploadFiles(files: File): Promise<string>;
export async function uploadFiles(files: File[] | FileList): Promise<string[]>;
export async function uploadFiles(
  files: File | File[] | FileList
): Promise<string | string[]>;
export async function uploadFiles(
  files: File | File[] | FileList
): Promise<string | string[]> {
  let fileArray: File[];
  let returnArray: boolean;
  if (Array.isArray(files)) {
    returnArray = true;
    fileArray = files;
  } else if (files instanceof File) {
    returnArray = false;
    fileArray = [files];
  } else if (files instanceof FileList) {
    returnArray = true;
    fileArray = [...files];
  } else {
    throw new TypeError(
      "Parameter files must be one of File, File[], FileList."
    );
  }

  const formData = new FormData();
  for (const file of fileArray) {
    formData.append("file[]", file);
  }

  try {
    const response = await http.post<StorageApiResponse<UploadPayload>>(
      "",
      formData
    );

    if (response.data.error) {
      throw new StorageError(response.data.message);
    }

    const fileIds = response.data.data.fileIds;
    return returnArray ? fileIds : fileIds[0];
  } catch (error) {
    handleError(error);
  }
}

export async function updateFile(file_id: string, file: File): Promise<void> {
  if (!file_id || typeof file_id != "string")
    throw new TypeError("File ID must be a string");
  if (!(file instanceof File)) {
    throw new TypeError("Parameter 'file' must be instance of File.");
  }

  const formData = new FormData();
  formData.set("file", file);

  try {
    const response = await http.put<StorageApiResponse<null>>(
      `/${file_id}`,
      formData
    );
    if (response.data.error) {
      throw new StorageError(response.data.message);
    }
  } catch (error) {
    handleError(error);
  }
}

export function getFileURL(file_id: string): string {
  if (!file_id || typeof file_id != "string")
    throw new TypeError("File ID must be a string");

  return `${STORAGE_API_URL}/${file_id}`;
}
export function getFileDownloadURL(file_id: string): string {
  if (!file_id || typeof file_id != "string")
    throw new TypeError("File ID must be a string");

  return `${STORAGE_API_URL}/${file_id}/download`;
}
export function getArchiveURL(
  file_ids: string[],
  archiveName?: string
): string {
  if (!file_ids || file_ids.length == 0)
    throw new TypeError("Provide at least one file id");
  const params = new URLSearchParams();
  for (const id of file_ids) {
    params.append("files", id);
  }
  if (archiveName) params.set("name", archiveName);
  return `${STORAGE_API_URL}/archive?${params.toString()}`;
}
/**
 * Build a URL to download files attached to invoices/bills/costs.
 */
export function getAttachmentsOfEntityURL(
  type: "invoices" | "bills" | "costs",
  ids: number[],
  archiveName?: string
): string {
  if (!ids || ids.length == 0) throw new TypeError("Provide at least one id");
  const params = new URLSearchParams();
  for (const id of ids) {
    params.append(type, id.toString());
  }
  if (archiveName) params.set("name", archiveName);
  return `${STORAGE_API_URL}/archive/${type}?${params.toString()}`;
}
export function getInvoicesAttachmentsURL(
  ids: number[],
  archiveName?: string
): string {
  return getAttachmentsOfEntityURL("invoices", ids, archiveName);
}
export function getBillsAttachmentsURL(
  ids: number[],
  archiveName?: string
): string {
  return getAttachmentsOfEntityURL("bills", ids, archiveName);
}
export function getCostsAttachmentsURL(
  ids: number[],
  archiveName?: string
): string {
  return getAttachmentsOfEntityURL("costs", ids, archiveName);
}

export async function getFileInfos(file_ids: string[]) {
  return Promise.all(file_ids.map((file_id) => getFileInfo(file_id)));
}
export async function getFileInfo(file_id: string): Promise<FileInfoPayload> {
  if (!file_id || typeof file_id != "string")
    throw new TypeError("File ID must be a string");

  try {
    const response = await http.get<StorageApiResponse<FileInfoPayload>>(
      `/${file_id}/info`
    );

    if (response.data.error) {
      throw new StorageError(response.data.message);
    }

    return response.data.data;
  } catch (error) {
    handleError(error);
  }
}

export async function deleteFile(file_id: string): Promise<void> {
  if (!file_id) throw new TypeError("File ID must be a string");

  try {
    const response = await http.delete(`/${file_id}`);
    if (response.data.error) {
      throw new StorageError(response.data.message);
    }
  } catch (error) {
    handleError(error);
  }
}
function handleError(error: unknown): never {
  if (isAxiosError(error)) {
    handleNetworkError(error);
    handleServerError(error);
    handlePayloadSizeError(error);
    handleErrorMessage(error);
  }
  throw error;
}

/**
 * Throws NetworkError if on response received.
 * Probably a network error.
 */
function handleNetworkError(error: AxiosError) {
  if (error.code == "ERR_NETWORK" || !error.response) {
    throw new NetworkError({ cause: error });
  }
}
/**
 * Throws StorageError if status is 413
 */
function handlePayloadSizeError(error: AxiosError) {
  if (error.response!.status == 413) {
    throw new StorageError("Total payload size exceeded the limit.");
  }
}
/**
 * Throws ServerError if status is 500
 */
function handleServerError(error: AxiosError) {
  if (error.response!.status == 500) {
    throw new ServerError(error.response?.data, { cause: error });
  }
}
/**
 * Extract actual error message from AxiosError and rethrow it as StorageError
 */
function handleErrorMessage(error: AxiosError) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = error.response!.data as any;
  const errorMessage = data?.message ?? data?.error?.message;
  if (errorMessage) {
    throw new StorageError(errorMessage, { cause: error });
  }
}
function isAxiosError(error: unknown): error is AxiosError {
  return error instanceof AxiosError;
}

class ServerError extends Error {
  data?: unknown;
  constructor(data?: unknown, options?: ErrorOptions) {
    super("Internal server error", options);
    this.data = data;
  }
}
class NetworkError extends Error {
  constructor(options?: ErrorOptions) {
    super("Request failed due to a network error.", options);
  }
}

class StorageError extends Error {
  constructor(message: string, options?: ErrorOptions) {
    super(message, options);
    this.name = "StorageError";
  }
}
