import config from "@/config";
import * as authTokens from "@/stores/auth-tokens";
import type { AuthConfig } from "@urql/exchange-auth";
import { authExchange } from "@urql/exchange-auth";
import { Client, ClientOptions, cacheExchange, fetchExchange } from "@urql/vue";
import { refreshAccessMutation } from ".";

export const clientConfig: ClientOptions = {
  url: config.API_URL_GRAPHQL,
  requestPolicy: "cache-and-network",
  exchanges: [
    cacheExchange,
    authExchange(async (utils): Promise<AuthConfig> => {
      return {
        addAuthToOperation(operation) {
          if (!authTokens.accessToken) return operation;
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${authTokens.accessToken}`,
          });
        },
        didAuthError(error) {
          return (
            error.response?.status === 401 ||
            error.graphQLErrors.some((e) => e.extensions.code == "invalid-jwt")
          );
        },
        async refreshAuth() {
          console.debug(
            "[SDK Client]: Authorization error occurred, trying to refresh the access..."
          );
          const refreshToken = authTokens.refreshToken;
          if (!refreshToken) {
            console.debug(
              "[SDK Client]: Skip refresh because there is no refresh token."
            );
            authTokens.clear();
            return;
          }

          // Stash current state before executing mutation.
          // If the mutation fails due to a network error, the store state will be restored.
          authTokens.stash();
          const result = await utils.mutate(refreshAccessMutation, {
            refresh_token: refreshToken,
          });

          if (result.error) {
            if (result.error.networkError) {
              console.warn(
                "[SDK Client]: Refresh failed due to network error. The tokens remain the same.",
                result.error.message
              );
              authTokens.restore();
              return;
            } else if (
              result.error.graphQLErrors.some(
                (e) => e.extensions.code == "JwtTokenExpired"
              )
            ) {
              console.debug(
                "[SDK Client]: Refresh failed due to expired token."
              );
            } else {
              console.error(
                "[SDK Client]: Refresh failed with unexpected error.",
                result.error.message
              );
            }
            authTokens.clear();
            return;
          }
          const tokens = result.data?.users_refresh;
          if (tokens) {
            console.debug("[SDK Client]: Refresh succeeded.");
            if (!tokens.refresh_token) {
              console.warn(
                "[SDK Client]: The response is missing refresh_token. Continue to use old refresh token."
              );
              tokens.refresh_token = refreshToken;
            }
            authTokens.save(
              tokens.access_token,
              tokens.expires_in,
              tokens.refresh_token
            );
          } else {
            console.error(
              "[SDK Client]: The request was successful, but data is missing."
            );
          }
        },
      };
    }),
    fetchExchange,
  ],
};

export const client = new Client(clientConfig);

export * as Types from "./types";

export * from "./queries/accounts";
export * from "./queries/analytics";
export * from "./queries/auth";
export * from "./queries/bills";
export * from "./queries/clients";
export * from "./queries/cost-types";
export * from "./queries/costs";
export * from "./queries/currency";
export * from "./queries/feed";
export * from "./queries/invoices";
export * from "./queries/notifications";
export * from "./queries/payment";
export * from "./queries/projects";
export * from "./queries/roles";
export * from "./queries/scopes";
export * from "./queries/services";
export * from "./queries/subsidiaries";
export * from "./queries/suppliers";
export * from "./queries/user";
export * from "./queries/users";
export * from "./rest-api";
export * from "./simple-storage";
