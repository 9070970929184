import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";

export const getCurrenciesQuery = graphql(/* GraphQL */ `
  query getCurrencies {
    currencies {
      value
    }
  }
`);

export function useGetCurrencies() {
  return useQuery({
    query: getCurrenciesQuery,
  });
}

export async function getCurrencies() {
  const response = await client.query(getCurrenciesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.currencies;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const searchCurrenciesQuery = graphql(/* GraphQL */ `
  query searchCurrencies($search: String = "%") {
    currencies(where: { value: { _ilike: $search } }, limit: 25) {
      value
    }
  }
`);

export async function searchCurrencies(search?: string) {
  const response = await client
    .query(searchCurrenciesQuery, { search })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.currencies || [];
}
