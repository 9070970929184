declare global {
  interface Window {
    besh: {
      debug: {
        [key: string]: unknown;
      };
      [key: string]: unknown;
    };
  }
}
window.besh = {
  debug: {},
};

/**
 * Add an object to `window.besh[key]`. Works only in development.
 * @param key a key to access `exposedObject`
 * @param exposedObject object to expose
 */
export function exposeToConsole(key: string, exposedObject: unknown) {
  if (import.meta.env.DEV) {
    window.besh[key] = exposedObject;
  }
}

export function exposeToDebug(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposedFunction: (...args: any[]) => any,
  key?: string
): void;
export function exposeToDebug(exposedObject: object, key: string): void;
export function exposeToDebug(exposedObject: unknown, key?: string) {
  if (import.meta.env.DEV) {
    console.log("Expose func");
    if (typeof exposedObject == "function") {
      window.besh.debug[key || exposedObject.name] = exposedObject;
      return;
    } else if (key) {
      window.besh.debug[key] = exposedObject;
      return;
    } else {
      throw new Error("Key is required when exposing an object");
    }
  }
}

export function unexposeConsole(key: string) {
  if (import.meta.env.DEV) {
    delete window.besh[key];
  }
}

export function unexposeDebug(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyOrFunction: string | ((...args: any[]) => any)
) {
  if (import.meta.env.DEV) {
    delete window.besh[
      typeof keyOrFunction == "function" ? keyOrFunction.name : keyOrFunction
    ];
  }
}
