import * as sdk from "@/sdk";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import * as authTokens from "./auth-tokens";

export enum UserStoreStates {
  NotInitialized,
  Loading,
  Authorized,
  Unauthorized,
}

export type UserData = {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  additional_name?: string | null;
  avatar_file_id?: string | null;
};
export type AccountData = {
  id: string;
  name: string;
  role_id: string;
  scopes: Array<string>;
  additional_scopes: Array<unknown>;
  allowed_subsidiaries: Array<unknown>;
  allowed_projects: Array<unknown>;
};

export const useUserStore = defineStore("user", () => {
  const state = ref(UserStoreStates.NotInitialized);
  const user = ref<UserData | null>(null);
  const accounts = ref<AccountData[]>([]);
  const activeAccount = ref<AccountData | null>(null);
  const avatarUrl = computed<string | null>(() => {
    if (user.value?.avatar_file_id) {
      return sdk.getFileURL(user.value.avatar_file_id);
    } else {
      return null;
    }
  });

  const isAuthorized = computed(
    () => state.value == UserStoreStates.Authorized
  );
  const accessScopes = computed(() => parseScopes(activeAccount.value?.scopes));
  const getInitials = computed(
    () =>
      user.value &&
      `${user.value.first_name} ${[
        user.value.last_name,
        user.value.additional_name,
      ]
        .map((name) => name && name.charAt(0) + ".")
        .join("")}`
  );

  // Logout when tokens clear triggered externally usually by the sdk client when refresh is failed
  authTokens.on("clear", () => {
    if (state.value == UserStoreStates.Authorized) {
      console.debug("[User Store] Tokens has been cleared. Logout...");
      state.value = UserStoreStates.Unauthorized;
      user.value = null;
      accounts.value = [];
      location.reload();
    }
  });

  async function load() {
    if (state.value == UserStoreStates.Loading) return;
    if (!authTokens.accessToken) {
      console.debug(
        "[User Store] Skip loading user information: no access token."
      );
      state.value = UserStoreStates.Unauthorized;
      return;
    }
    state.value = UserStoreStates.Loading;
    console.debug("[User Store] Load user information...");

    try {
      const data = await sdk.getMyUser();

      user.value = {
        id: data.user.id as string,
        username: data.user.username,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        additional_name: data.user.additional_name,
        avatar_file_id: data.user.avatar_file_id,
      };
      const scopes = data.account.scopes
        ? (data.account.scopes.filter(
            (item) => typeof item == "string"
          ) as Array<string>)
        : [];
      activeAccount.value = {
        id: data.account.id,
        name: data.account.name,
        role_id: data.account.role_id,
        scopes: scopes,
        additional_scopes: [],
        allowed_subsidiaries: [],
        allowed_projects: [],
      };
      accounts.value = data.accounts
        .filter((item) => item !== null)
        .map((item) => ({
          id: item!.id,
          name: item!.name,
          role_id: item!.role_id,
          scopes: item!.scopes
            ? (item!.scopes.filter(
                (item) => typeof item == "string"
              ) as Array<string>)
            : [],
          additional_scopes: [],
          allowed_subsidiaries: [],
          allowed_projects: [],
        }));

      state.value = UserStoreStates.Authorized;
      console.debug("[User Store] User information loaded.");
    } catch (error) {
      console.error("[User Store] Current user loading error", error);
      $reset();
    }
  }

  let loadingPromise: ReturnType<typeof load> | null = null;
  async function request() {
    switch (state.value) {
      case UserStoreStates.NotInitialized:
        loadingPromise = load();
        return loadingPromise;
      case UserStoreStates.Loading:
        return loadingPromise as ReturnType<typeof load>;
      case UserStoreStates.Authorized:
      case UserStoreStates.Unauthorized:
        return;
    }
  }

  async function switchAccount(accountId: AccountData["id"]) {
    if (state.value != UserStoreStates.Authorized) {
      console.warn("[User Store] You can switch account when Authorized only");
      return;
    }

    const targetAccount = accounts.value.find((acc) => acc.id == accountId);
    if (!targetAccount) {
      console.error(
        `[User Store] This user has no account with id '${accountId}'`
      );
      return;
    }

    console.debug(`[User Store] Switch to account ${accountId}...`);
    const { accessToken, expiresIn, refreshToken } = await sdk.switchAccount(
      accountId
    );
    authTokens.save(accessToken, expiresIn, refreshToken);
    activeAccount.value = targetAccount;
  }

  async function update(
    data: Partial<
      Pick<
        UserData,
        "first_name" | "last_name" | "additional_name" | "avatar_file_id"
      >
    >
  ) {
    if (state.value != UserStoreStates.Authorized || !user.value) {
      console.warn(
        "[User Store] You only can update user data when Authorized"
      );
      return;
    }

    const newData = await sdk.updateMyUser({
      first_name: data.first_name ?? user.value.first_name,
      last_name: data.last_name ?? user.value.last_name,
      additional_name:
        data.additional_name === undefined
          ? user.value.additional_name
          : data.additional_name,
      avatar_file_id:
        data.avatar_file_id === undefined
          ? user.value.avatar_file_id
          : data.avatar_file_id,
    });

    user.value!.first_name = newData.first_name;
    user.value!.last_name = newData.last_name;
    user.value!.additional_name = newData.additional_name;
    user.value!.avatar_file_id = newData.avatar_file_id;
  }

  async function login(
    username: string,
    password: string,
    saveSession: boolean = true
  ) {
    if (
      state.value == UserStoreStates.Loading ||
      state.value == UserStoreStates.Authorized
    ) {
      console.warn(`[User Store] You cannot login in ${state.value} state`);
      return;
    }

    const { accessToken, expiresIn, refreshToken } = await sdk.login(
      username as string,
      password as string
    );
    authTokens.setStorage(saveSession ? localStorage : sessionStorage);
    authTokens.save(accessToken, expiresIn, refreshToken);
    await load();
  }

  function logout() {
    if (state.value == UserStoreStates.Authorized) {
      console.debug("[User Store] Logout...");
      state.value = UserStoreStates.Unauthorized;
      user.value = null;
      accounts.value = [];
      authTokens.clear();
    }
  }

  function $reset() {
    console.debug("[User Store] Reset store...");
    state.value = UserStoreStates.NotInitialized;
    user.value = null;
    accounts.value = [];
    authTokens.clear();
  }

  return {
    state,
    user,
    getInitials,
    accounts,
    isAuthorized,
    activeAccount,
    accessScopes,
    avatarUrl,

    login,
    logout,

    load,
    request,
    switchAccount,
    update,
    $reset,
  };
});

function parseScopes(scopes: string[] = []) {
  const _scopes = new Set(scopes);

  const admin = _scopes.has("admin");
  const adminUser = admin || _scopes.has("admin:user");
  const adminInvoice = admin || _scopes.has("admin:invoice");
  const adminBill = admin || _scopes.has("admin:bill");
  const adminCost = admin || _scopes.has("admin:cost");
  const adminPayment = admin || _scopes.has("admin:payment");
  const adminProject = admin || _scopes.has("admin:project");
  const adminCompany = admin || _scopes.has("admin:company");
  const adminCostType = admin || _scopes.has("admin:cost_type");
  const adminService = admin || _scopes.has("admin:service");
  const adminRole = admin || _scopes.has("admin:role");

  const invoice = adminInvoice || _scopes.has("invoice");
  const invoiceRead = invoice || _scopes.has("invoice:read");
  const invoiceWrite = invoice || _scopes.has("invoice:write");

  const validateInvoice = adminInvoice || _scopes.has("validate_invoice");
  const closeInvoice = adminInvoice || _scopes.has("close_invoice");

  const bill = adminBill || _scopes.has("bill");
  const billRead = bill || _scopes.has("bill:read");
  const billWrite = bill || _scopes.has("bill:write");

  const cost = adminCost || _scopes.has("cost");
  const costRead = cost || _scopes.has("cost:read");
  const costWrite = cost || _scopes.has("cost:write");

  const approveCost = adminCost || _scopes.has("approve_cost");

  const company = adminCompany || _scopes.has("company");
  const companyRead = company || _scopes.has("company:read");
  const companyWrite = company || _scopes.has("company:write");
  const companyWriteClient =
    companyWrite || _scopes.has("company:write_client");
  const companyWriteSupplier =
    companyWrite || _scopes.has("company:write_supplier");
  const companyWriteSubsidiary =
    companyWrite || _scopes.has("company:write_subsidiary");

  const costType = adminCostType || _scopes.has("cost_type");
  const costTypeRead = costType || _scopes.has("cost_type:read");
  const costTypeWrite = costType || _scopes.has("cost_type:write");

  const service = adminService || _scopes.has("service");
  const serviceRead = service || _scopes.has("service:read");
  const serviceWrite = service || _scopes.has("service:write");

  const project = adminProject || _scopes.has("project");
  const projectRead = project || _scopes.has("project:read");
  const projectWrite = project || _scopes.has("project:write");

  const payment = adminPayment || _scopes.has("payment");
  const paymentRead = payment || _scopes.has("payment:read");
  const paymentWrite = payment || _scopes.has("payment:write");

  const createReport = admin || _scopes.has("create_report");

  return {
    admin,
    adminUser,
    adminInvoice,
    adminBill,
    adminCost,
    adminPayment,
    adminProject,
    adminCompany,
    adminCostType,
    adminService,
    adminRole,
    invoice,
    invoiceRead,
    invoiceWrite,
    validateInvoice,
    closeInvoice,
    bill,
    billRead,
    billWrite,
    cost,
    costRead,
    costWrite,
    approveCost,
    company,
    companyRead,
    companyWrite,
    companyWriteClient,
    companyWriteSupplier,
    companyWriteSubsidiary,
    costType,
    costTypeRead,
    costTypeWrite,
    service,
    serviceRead,
    serviceWrite,
    project,
    projectRead,
    projectWrite,
    payment,
    paymentRead,
    paymentWrite,
    createReport,
  };
}
