import createErrorWithCode from "@/utils/error-with-code";
import { useMutation, useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";
import { UpdateMyUserMutationVariables } from "../gql/graphql";

export const InvalidCurrentPasswordError = createErrorWithCode(
  "invalid_password",
  "Current password is invalid"
);

export const getMyUserQuery = graphql(/* GraphQL */ `
  query getMyUser {
    me {
      user {
        id
        username
        first_name
        last_name
        additional_name
        avatar_file_id
        created_at
        updated_at
      }
      account {
        id
        name
        role_id
        scopes
      }
      accounts {
        id
        name
        role_id
        scopes
      }
    }
  }
`);
export const changeMyPasswordMutation = graphql(/* GraphQL */ `
  mutation ChangePassword($new_password: String!, $old_password: String!) {
    users_change_password(
      new_password: $new_password
      old_password: $old_password
    ) {
      updated_at
    }
  }
`);

export function useMyUser() {
  return useQuery({
    query: getMyUserQuery,
  });
}
export function useChangeMyPassword() {
  return useMutation(changeMyPasswordMutation);
}

export async function getMyUser() {
  const response = await client.query(getMyUserQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }
  const data = response.data?.me;
  if (!data) {
    throw new Error("No data received");
  }
  if (!data.user) {
    throw new Error("No user data");
  }
  if (!data.account) {
    throw new Error("No current account data");
  }
  if (!data.accounts) {
    throw new Error("No accounts data");
  }

  return {
    user: data.user,
    account: data.account,
    accounts: data.accounts,
  };
}

export async function changeMyPassword(
  old_password: string,
  new_password: string
): Promise<void> {
  const { error } = await client
    .mutation(changeMyPasswordMutation, {
      old_password,
      new_password,
    })
    .toPromise();

  if (error) {
    if (
      error.graphQLErrors.some((e) => e.extensions.code == "InvalidCredentials")
    ) {
      throw new InvalidCurrentPasswordError();
    }
    throw new Error(error?.message);
  }
}

export const updateMyUserMutation = graphql(/* GraphQL */ `
  mutation updateMyUser(
    $first_name: String
    $last_name: String
    $additional_name: String
    $avatar_file_id: String
  ) {
    update_users_me(
      first_name: $first_name
      last_name: $last_name
      additional_name: $additional_name
      avatar_file_id: $avatar_file_id
    ) {
      additional_name
      avatar_file_id
      first_name
      id
      last_name
      updated_at
      username
    }
  }
`);

export async function updateMyUser(params: UpdateMyUserMutationVariables) {
  const response = await client
    .mutation(updateMyUserMutation, params)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_users_me;
  if (!data) {
    throw new Error("No data received");
  }
  return data;
}
