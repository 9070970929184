import config from "@/config";
import { useUserStore } from "@/stores/user";
import { createRouter, createWebHistory } from "vue-router";

function checkScope(
  scope: keyof ReturnType<typeof useUserStore>["accessScopes"]
) {
  return () => {
    const userStore = useUserStore();
    if (userStore.accessScopes[scope]) return true;
    else {
      return {
        name: "NoAccess",
        replace: true,
      };
    }
  };
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "Login",
      path: "/login",
      component: () => import("@/router/LoginView.vue"),
      async beforeEnter() {
        const userStore = useUserStore();
        await userStore.request();
        if (!userStore.isAuthorized) return true;
        else if (!history.state.redirectedToLogin) {
          console.debug("User is authorized. Redirecting to home...");
          return { name: "WelcomeView", replace: true };
        } else {
          console.warn(
            "Redirect loop detected, reset the session. This could be a navigation guard bug or a result of deprecated token usage."
          );
          userStore.logout();
        }
      },
    },
    {
      path: "",
      component: () => import("@/router/ApplicationShell.vue"),
      async beforeEnter(to) {
        const userStore = useUserStore();
        await userStore.request();
        if (userStore.isAuthorized) return true;
        else {
          console.debug("User is not authorized. Redirecting to login...");
          return {
            name: "Login",
            query: { from: to.fullPath },
            replace: true,
            state: { redirectedToLogin: true },
          };
        }
      },
      children: [
        {
          name: "WelcomeView",
          path: "",
          component: () => import("@/router/WelcomeView.vue"),
          meta: { title: "Home" },
        },
        {
          name: "NoAccess",
          path: "no-access",
          component: () => import("@/router/NoAccessView.vue"),
          meta: { title: "Access Denied" },
        },
        {
          name: "UserSettings",
          path: "/user-settings",
          component: () => import("@/router/UserSettingsView.vue"),
          meta: { title: "User Settings" },
        },
        {
          name: "Bills",
          path: "bills",
          component: () => import("@/router/BillTableView.vue"),
          meta: { title: "Bills" },
          beforeEnter: checkScope("billRead"),
        },
        {
          name: "BillCreate",
          path: "bills/new",
          component: () => import("@/router/BillCreateView.vue"),
          meta: { title: "New Bill" },
          beforeEnter: checkScope("billWrite"),
        },
        {
          name: "BillDetails",
          path: "bills/:bill(\\d+)",
          component: () => import("@/router/BillDetailsView.vue"),
          meta: { title: "Bill Details" },
          beforeEnter: checkScope("billRead"),
        },
        {
          name: "Invoices",
          path: "invoices",
          component: () => import("@/router/InvoiceTableView.vue"),
          meta: { title: "Invoices" },
          beforeEnter: checkScope("invoiceRead"),
        },
        {
          name: "InvoiceCreate",
          path: "invoices/new",
          component: () => import("@/router/InvoiceCreateView.vue"),
          meta: { title: "New Invoice" },
          beforeEnter: checkScope("invoiceWrite"),
        },
        {
          name: "InvoiceDetails",
          path: "invoices/:invoice(\\d+)",
          component: () => import("@/router/InvoiceDetailsView.vue"),
          meta: { title: "Invoice Details" },
          beforeEnter: checkScope("invoiceRead"),
        },
        {
          name: "Costs",
          path: "costs",
          component: () => import("@/router/CostTableView.vue"),
          meta: { title: "Costs" },
          beforeEnter: checkScope("costRead"),
        },
        {
          name: "RedirectToInvoiceByCost",
          path: "redirect-to-invoice/cost/:cost_id(\\d+)",
          component: () => import("@/router/RedirectToInvoiceByCost.vue"),
          meta: { title: "Redirect to Invoice" },
          beforeEnter: checkScope("costRead"),
        },
        {
          name: "Analytics",
          path: "analytics",
          component: () => import("@/router/AnalyticsView.vue"),
          meta: { title: "Analytics" },
          beforeEnter: checkScope("createReport"),
        },
        {
          path: "admin",
          children: [
            {
              name: "AdminCustomer",
              path: "clients",
              component: () => import("@/router/Admin/CustomerTableView.vue"),
              meta: { title: "Clients" },
              beforeEnter: checkScope("adminCompany"),
            },
            {
              name: "AdminSupplier",
              path: "supplier",
              component: () => import("@/router/Admin/SupplierTableView.vue"),
              meta: { title: "Supplier" },
              beforeEnter: checkScope("adminCompany"),
            },
            {
              name: "AdminSubsidiary",
              path: "subsidiary",
              component: () => import("@/router/Admin/SubsidiaryTableView.vue"),
              meta: { title: "Subsidiary" },
              beforeEnter: checkScope("adminCompany"),
            },
            {
              name: "AdminProjects",
              path: "projects",
              component: () => import("@/router/Admin/ProjectsTableView.vue"),
              meta: { title: "Projects" },
              beforeEnter: checkScope("adminProject"),
            },
            {
              name: "AdminCostTypes",
              path: "cost-types",
              component: () => import("@/router/Admin/CostTypesTableView.vue"),
              meta: { title: "Cost Types" },
              beforeEnter: checkScope("adminCostType"),
            },
            {
              name: "AdminServices",
              path: "services",
              component: () => import("@/router/Admin/ServicesTableView.vue"),
              meta: { title: "Services" },
              beforeEnter: checkScope("adminService"),
            },
            {
              name: "AdminUsers",
              path: "users",
              component: () => import("@/router/Admin/UsersTableView.vue"),
              meta: { title: "Users" },
              beforeEnter: checkScope("adminUser"),
            },
            {
              name: "AdminUserEdit",
              path: "users/:id",
              component: () => import("@/router/Admin/UserEditView.vue"),
              props(to) {
                return {
                  userId: to.params.id,
                };
              },
              meta: { title: "Edit User" },
              beforeEnter: checkScope("adminUser"),
            },
            {
              name: "AdminRoles",
              path: "roles",
              component: () => import("@/router/Admin/RolesTableView.vue"),
              meta: { title: "Roles" },
              beforeEnter: checkScope("adminUser"),
            },
          ],
        },
      ],
    },
    {
      name: "Changelog",
      path: "/changelog",
      component: () => import("@/router/ChangelogView.vue"),
      meta: { title: "Changelog" },
    },
    {
      name: "NotFound",
      path: "/:pathMatch(.*)*",
      component: () => import("@/router/NotFoundView.vue"),
      meta: { title: "Not Found" },
    },
  ],
});

// Conditionally add testing grounds
if (config.INCLUDE_TEST_GROUND) {
  router.addRoute({
    path: "/test-grounds",
    children: [
      {
        name: "TestGround",
        path: "",
        component: () => import("@/router/TestGround/TestGround.vue"),
      },
      {
        name: "TestGroundButtons",
        path: "buttons",
        component: () => import("@/router/TestGround/TestGroundButtons.vue"),
      },
      {
        name: "TestGroundTextInput",
        path: "inputs/text",
        component: () => import("@/router/TestGround/TestGroundTextInput.vue"),
      },
      {
        name: "TestGroundPasswordInput",
        path: "inputs/password",
        component: () =>
          import("@/router/TestGround/TestGroundPasswordInput.vue"),
      },
      {
        name: "TestGroundNumberInput",
        path: "inputs/number",
        component: () =>
          import("@/router/TestGround/TestGroundNumberInput.vue"),
      },
      {
        name: "TestGroundCheckboxInput",
        path: "inputs/checkbox",
        component: () =>
          import("@/router/TestGround/TestGroundCheckboxInput.vue"),
      },
      {
        name: "TestGroundRadioInput",
        path: "inputs/radio",
        component: () => import("@/router/TestGround/TestGroundRadioInput.vue"),
      },
      {
        name: "TestGroundToggleInput",
        path: "inputs/toggle",
        component: () =>
          import("@/router/TestGround/TestGroundToggleInput.vue"),
      },
      {
        name: "TestGroundTextareaInput",
        path: "inputs/textarea",
        component: () =>
          import("@/router/TestGround/TestGroundTextareaInput.vue"),
      },
      {
        name: "TestGroundSelectInput",
        path: "inputs/select",
        component: () =>
          import("@/router/TestGround/TestGroundSelectInput.vue"),
      },
      {
        name: "TestGroundAvatarInput",
        path: "inputs/avatar",
        component: () =>
          import("@/router/TestGround/TestGroundAvatarInput.vue"),
      },
      {
        name: "TestGroundDateInput",
        path: "inputs/date",
        component: () => import("@/router/TestGround/TestGroundDateInput.vue"),
      },
      {
        name: "TestGroundComboboxInput",
        path: "inputs/combobox",
        component: () =>
          import("@/router/TestGround/TestGroundComboboxInput.vue"),
      },
      {
        name: "TestGroundFileInput",
        path: "inputs/file",
        component: () => import("@/router/TestGround/TestGroundFileInput.vue"),
      },
      {
        name: "TestGroundStateChip",
        path: "state-chip",
        component: () => import("@/router/TestGround/TestGroundStateChip.vue"),
      },
      {
        name: "TestGroundNotificationItem",
        path: "notification-item",
        component: () =>
          import("@/router/TestGround/TestGroundNotificationItem.vue"),
      },
      {
        name: "TestGroundUserDropdown",
        path: "user-dropdown",
        component: () =>
          import("@/router/TestGround/TestGroundUserDropdown.vue"),
      },
      {
        name: "TestGroundDialogConfirmation",
        path: "dialog-confirmation",
        component: () =>
          import("@/router/TestGround/TestGroundDialogConfirmation.vue"),
      },
      {
        name: "TestGroundStepBar",
        path: "step-bar",
        component: () => import("@/router/TestGround/TestGroundStepBar.vue"),
      },
      {
        name: "TestGroundCalendar",
        path: "calendar",
        component: () => import("@/router/TestGround/TestGroundCalendar.vue"),
      },
      {
        name: "TestGroundFilters",
        path: "filters",
        component: () => import("@/router/TestGround/TestGroundFilters.vue"),
      },
    ],
  });
}

export default router;

declare module "vue-router" {
  interface RouteMeta {
    title?: string;
  }
}
