export function symmetricDiff<T>(a: Set<T>, b: Set<T>) {
  const difference = new Set(a);
  for (const item of b) {
    if (difference.has(item)) {
      difference.delete(item);
    } else {
      difference.add(item);
    }
  }
  return difference;
}

export function diff<T>(a: Set<T>, b: Set<T>) {
  const difference = new Set(a);
  for (const item of b) {
    if (difference.has(item)) {
      difference.delete(item);
    }
  }
  return difference;
}
