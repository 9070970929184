import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";
import { MaybeRef } from "vue";
import type { ListProjectsQueryVariables } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listProjectsQuery = graphql(/* GraphQL */ `
  query listProjects(
    $where: projects_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [projects_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = true
    $includeCreatedAt: Boolean = false
  ) {
    projects_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    projects(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      name @include(if: $includeName)
      created_at @include(if: $includeCreatedAt)
    }
  }
`);

export type ListProjectsIncludesValue =
  IncludesProp<ListProjectsQueryVariables>;

export interface ListProjectsVariables {
  limit?: MaybeRef<ListProjectsQueryVariables["limit"]>;
  offset?: MaybeRef<ListProjectsQueryVariables["offset"]>;
  order_by?: MaybeRef<ListProjectsQueryVariables["order_by"]>;
  where?: MaybeRef<ListProjectsQueryVariables["where"]>;
  includes: MaybeRef<ListProjectsIncludesValue>;
}

export function useListProjects(props: ListProjectsVariables) {
  return useQuery({
    query: listProjectsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListProjectsQueryVariables,
  });
}

export async function listProjects(props: ListProjectsVariables) {
  const response = await client
    .query(listProjectsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListProjectsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.projects;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getProjectQuery = graphql(/* GraphQL */ `
  query getProject($id: uuid!) {
    projects_by_pk(id: $id) {
      id
      name
    }
  }
`);

export async function getProject(id: string) {
  const response = await client.query(getProjectQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.projects_by_pk;
  return data;
}

export const getProjectsQuery = graphql(/* GraphQL */ `
  query getProjects {
    projects {
      id
      name
    }
  }
`);

export function useGetProjects() {
  return useQuery({
    query: getProjectsQuery,
  });
}

export async function getProjects() {
  const response = await client.query(getProjectsQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.projects;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createProjectMutation = graphql(/* GraphQL */ `
  mutation createProject($name: String!) {
    insert_projects_one(object: { name: $name }) {
      id
      name
    }
  }
`);

export async function createProject(name: string) {
  const response = await client
    .mutation(createProjectMutation, { name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.insert_projects_one;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const updateProjectMutation = graphql(/* GraphQL */ `
  mutation updateProject($id: uuid!, $name: String!) {
    update_projects_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`);

export async function updateProject(id: string, name: string) {
  const response = await client
    .mutation(updateProjectMutation, { id, name })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_projects_by_pk;
  if (!data) {
    throw new Error("Project not found");
  }

  return data;
}

export const deleteProjectMutation = graphql(/* GraphQL */ `
  mutation deleteProject($id: uuid!) {
    delete_projects_by_pk(id: $id) {
      id
      name
      created_at
    }
  }
`);

export async function deleteProject(id: string) {
  const response = await client
    .mutation(deleteProjectMutation, { id })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const data = response.data?.delete_projects_by_pk;
  if (!data) {
    throw new Error("Project not found");
  }

  return data;
}
