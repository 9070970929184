export class ErrorWithCode<T = string> extends Error {
  code: T;
  constructor(code: T, message: string, options?: ErrorOptions) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message, options);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithCode);
    }

    this.name = "ErrorWithCode";
    this.code = code;
  }
}

export default function createErrorWithCode(
  code: string,
  defaultMessage: string
) {
  return class extends ErrorWithCode {
    constructor(message: string = defaultMessage, options?: ErrorOptions) {
      super(code, message, options);
    }
  };
}
