import intervalToYearPeriodWhere from "@/utils/interval-to-year-period-where";
import { useQuery } from "@urql/vue";
import { DateTime } from "luxon";
import { MaybeRef, MaybeRefOrGetter, computed, toValue, unref } from "vue";
import { graphql } from "../gql";

export const getAnalyticsDataQuery = graphql(/* GraphQL */ `
  query getDataForAnalytics(
    $projectId: uuid!
    $costs_and: [costs_bool_exp!]!
    $bills_and: [bills_bool_exp!]!
  ) {
    projects(where: { id: { _eq: $projectId } }) {
      costs(
        where: {
          status: { _eq: approved }
          is_deleted: { _eq: false }
          _and: $costs_and
        }
      ) {
        amount_usd_w_vat
        year
        period
      }
      bills(where: { is_deleted: { _eq: false }, _and: $bills_and }) {
        id
        amount_usd_w_vat
        year
        period
      }
    }
  }
`);

export function useAnalyticsData(
  projectId: MaybeRefOrGetter<string>,
  start: MaybeRef<DateTime>,
  end: MaybeRef<DateTime>
) {
  const wherePeriods = computed(() =>
    intervalToYearPeriodWhere(unref(start), unref(end))
  );

  const variables = computed(() => ({
    projectId: toValue(projectId),
    costs_and: wherePeriods.value,
    bills_and: wherePeriods.value,
  }));

  return useQuery({
    query: getAnalyticsDataQuery,
    variables: variables,
  });
}
