import { useMutation, useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import {
  ListCostsVariables,
  ListPaymentsVariables,
  client,
  listCostsQuery,
  listPaymentsQuery,
} from "..";
import { graphql } from "../gql";
import {
  Bills_Statuses_Enum,
  ListCostsQueryVariables,
  ListPaymentsQueryVariables,
  type Bills_Insert_Input,
  type Bills_Set_Input,
  type ListBillsQueryVariables,
} from "../gql/graphql";
import { deleteFile, uploadFiles } from "../simple-storage";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listBillsQuery = graphql(/* GraphQL */ `
  query listBills(
    $where: bills_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [bills_order_by!] = {}
    $includeActId: Boolean = false
    $includeAmount: Boolean = false
    $includeAmountUsd: Boolean = false
    $includeAmountUsdWVat: Boolean = false
    $includeAmountWVat: Boolean = false
    $includeClient: Boolean = false
    $includeClosedBy: Boolean = false
    $includeCreatedAt: Boolean = false
    $includeCreatedBy: Boolean = false
    $includeCurrency: Boolean = false
    $includeDescription: Boolean = false
    $includeDueDate: Boolean = false
    $includeId: Boolean = true
    $includeInvoiceDate: Boolean = false
    $includeInvoiceRef: Boolean = false
    $includeIsPaid: Boolean = false
    $includeIsDeleted: Boolean = false
    $includeName: Boolean = false
    $includePeriod: Boolean = false
    $includeProject: Boolean = false
    $includeService: Boolean = false
    $includeStatus: Boolean = false
    $includeCostsStatus: Boolean = false
    $includePaymentStatus: Boolean = false
    $includeSubsidiary: Boolean = false
    $includeVat: Boolean = false
    $includeYear: Boolean = false
  ) {
    bills_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    bills(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      act_id @include(if: $includeActId)
      amount @include(if: $includeAmount)
      amount_usd @include(if: $includeAmountUsd)
      amount_usd_w_vat @include(if: $includeAmountUsdWVat)
      amount_w_vat @include(if: $includeAmountWVat)
      client @include(if: $includeClient) {
        id
        short_name
        legal_name
      }
      closed_by @include(if: $includeClosedBy) {
        id
        first_name
        last_name
        additional_name
      }
      created_at @include(if: $includeCreatedAt)
      created_by @include(if: $includeCreatedBy) {
        id
        first_name
        last_name
        additional_name
      }
      currency @include(if: $includeCurrency)
      description @include(if: $includeDescription)
      due_date @include(if: $includeDueDate)
      id @include(if: $includeId)
      invoice_date @include(if: $includeInvoiceDate)
      invoice_ref @include(if: $includeInvoiceRef)
      is_paid @include(if: $includeIsPaid)
      is_deleted @include(if: $includeIsDeleted)
      name @include(if: $includeName)
      period @include(if: $includePeriod)
      project @include(if: $includeProject) {
        id
        name
      }
      service @include(if: $includeService) {
        id
        name
      }
      status @include(if: $includeStatus)
      costs_status @include(if: $includeCostsStatus)
      payment_status @include(if: $includePaymentStatus)
      subsidiary @include(if: $includeSubsidiary) {
        id
        short_name
        legal_name
      }
      vat @include(if: $includeVat)
      year @include(if: $includeYear)
    }
  }
`);

export type ListBillsIncludesValue = IncludesProp<ListBillsQueryVariables>;

export interface ListBillsVariables {
  limit?: MaybeRef<ListBillsQueryVariables["limit"]>;
  offset?: MaybeRef<ListBillsQueryVariables["offset"]>;
  order_by?: MaybeRef<ListBillsQueryVariables["order_by"]>;
  where?: MaybeRef<ListBillsQueryVariables["where"]>;
  includes: MaybeRef<ListBillsIncludesValue>;
}

export function useListBills(props: ListBillsVariables) {
  return useQuery({
    query: listBillsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListBillsQueryVariables,
  });
}

export async function listBills(props: ListBillsVariables) {
  const response = await client
    .query(listBillsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListBillsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.bills;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getBillQuery = graphql(/* GraphQL */ `
  query getBill($id: Int!) {
    bills_by_pk(id: $id) {
      act_id
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      client {
        id
        short_name
        legal_name
      }
      closed_by {
        additional_name
        first_name
        id
        last_name
      }
      created_at
      created_by {
        additional_name
        first_name
        id
        last_name
      }
      currency
      description
      due_date
      id
      invoice_date
      invoice_ref
      is_paid
      is_deleted
      name
      period
      project {
        id
        name
      }
      service {
        id
        name
      }
      status
      costs_status
      payment_status
      subsidiary {
        id
        short_name
        legal_name
      }
      vat
      year
      files {
        id
        file_id
      }
      feeds(order_by: { created_at: desc }) {
        action
        changes
        created_at
        event_id
        user_id: hasura_user(path: "x-hasura-user-id")
        row_data
        row_id
        table_name
      }
    }
  }
`);

export function useGetBill(billId: MaybeRef<number>) {
  return useQuery({
    query: getBillQuery,
    variables: {
      // Hack: have to override the type. See known issues in README
      id: billId as number,
    },
  });
}

export async function getBill(id: number) {
  const response = await client
    .query(getBillQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.bills_by_pk;
  return data;
}

export const updateBillMutation = graphql(/* GraphQL */ `
  mutation updateBill($id: Int!, $data: bills_set_input!) {
    update_bills_by_pk(pk_columns: { id: $id }, _set: $data) {
      act_id
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      client {
        id
        short_name
        legal_name
      }
      closed_by {
        additional_name
        first_name
        id
        last_name
      }
      created_at
      created_by {
        additional_name
        first_name
        id
        last_name
      }
      currency
      description
      due_date
      id
      invoice_date
      invoice_ref
      is_paid
      is_deleted
      name
      period
      project {
        id
        name
      }
      service {
        id
        name
      }
      status
      costs_status
      payment_status
      subsidiary {
        id
        short_name
        legal_name
      }
      vat
      year
      files {
        id
        file_id
      }
      feeds(order_by: { created_at: desc }) {
        action
        changes
        created_at
        event_id
        user_id: hasura_user(path: "x-hasura-user-id")
        row_data
        row_id
        table_name
      }
    }
  }
`);

export async function updateBill(id: number, data: Bills_Set_Input) {
  const response = await client
    .mutation(updateBillMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_bills_by_pk;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export const setBillDeletedMutation = graphql(/* GraphQL */ `
  mutation setBillDeleted($id: Int!, $is_deleted: Boolean!) {
    update_bills_by_pk(
      pk_columns: { id: $id }
      _set: { is_deleted: $is_deleted }
    ) {
      id
    }
  }
`);

export async function setBillDeleted(id: number, is_deleted: boolean) {
  const response = await client
    .mutation(setBillDeletedMutation, {
      id,
      is_deleted,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_bills_by_pk?.id;
  if (!_data) {
    throw new Error("Bill not found");
  }

  return _data;
}

export async function archiveBill(id: number) {
  return await setBillDeleted(id, true);
}
export async function unarchiveBill(id: number) {
  return await setBillDeleted(id, false);
}

export const updateBillsMutation = graphql(/* GraphQL */ `
  mutation updateBills($ids: [Int!]!, $data: bills_set_input!) {
    update_bills(where: { id: { _in: $ids } }, _set: $data) {
      affected_rows
      returning {
        # Triggers cache update
        __typename
        id
      }
    }
  }
`);

export async function updateBills(
  ids: number | number[],
  data: Bills_Set_Input
) {
  const response = await client
    .mutation(updateBillsMutation, {
      ids,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_bills;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export async function changeBillStatusValidated(ids: number | number[]) {
  return updateBills(ids, {
    status: Bills_Statuses_Enum.Validated,
  });
}
export async function changeBillStatusPaid(
  ids: number | number[],
  userId: string
) {
  // TODO: Pass closed_at
  return updateBills(ids, {
    status: Bills_Statuses_Enum.Paid,
    closed_by_id: userId,
  });
}

export const addBillFilesMutation = graphql(/* GraphQL */ `
  mutation addBillFiles($objects: [bills_files_insert_input!]!) {
    insert_bills_files(objects: $objects) {
      affected_rows
      returning {
        __typename
        bill {
          __typename
        }
      }
    }
  }
`);

export async function addFilesToBill(
  id: number,
  files: File | File[] | FileList
) {
  const fileIds = await uploadFiles(files);
  const fileIdArray = Array.isArray(fileIds) ? fileIds : [fileIds];

  const insertResponse = await client
    .mutation(addBillFilesMutation, {
      objects: fileIdArray.map((file_id) => ({
        bill_id: id,
        file_id,
      })),
    })
    .toPromise();

  if (insertResponse.error) {
    throw new Error(insertResponse.error.toString());
  }

  return insertResponse.data?.insert_bills_files?.affected_rows;
}

export const removeBillFilesMutation = graphql(/* GraphQL */ `
  mutation removeBillFiles($bill_id: Int!, $file_ids: [uuid!]!) {
    delete_bills_files(
      where: { bill_id: { _eq: $bill_id }, file_id: { _in: $file_ids } }
    ) {
      affected_rows
      returning {
        __typename
        file_id
        bill {
          __typename
        }
      }
    }
  }
`);

export async function removeFilesFromBill(id: number, file_ids: string[]) {
  const deleteResponse = await client
    .mutation(removeBillFilesMutation, {
      bill_id: id,
      file_ids,
    })
    .toPromise();

  if (deleteResponse.error) {
    throw new Error(deleteResponse.error.toString());
  }

  const deletedFileIds: string[] =
    deleteResponse.data?.delete_bills_files?.returning?.map(
      ({ file_id }) => file_id
    ) ?? [];

  if (deletedFileIds.length > 0) {
    await Promise.all(deletedFileIds.map((fileId) => deleteFile(fileId)));
  }

  return deletedFileIds;
}

export type ListBillCostsVariables = Omit<ListCostsVariables, "where">;

export function useListBillCosts(
  billId: MaybeRef<number>,
  props: ListBillCostsVariables
) {
  return useQuery({
    query: listCostsQuery,
    variables: {
      ...expandIncludes(props.includes),
      where: {
        bill_id: { _eq: billId },
      },
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      // Hack: have to override the type. See known issues in README
    } as ListCostsQueryVariables,
  });
}

export async function listBillCosts(billId: number) {
  const response = await client
    .query(listCostsQuery, {
      where: {
        bill_id: { _eq: billId },
      },
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export type ListBillPaymentsVariables = Omit<ListPaymentsVariables, "where">;

export function useListBillPayments(
  billId: MaybeRef<number>,
  props: ListBillPaymentsVariables
) {
  return useQuery({
    query: listPaymentsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: { bills_payments: { bill_id: { _eq: billId } } },
      // Hack: have to override the type. See known issues in README
    } as ListPaymentsQueryVariables,
  });
}

export async function listBillPayments(
  id: number,
  props: ListBillPaymentsVariables
) {
  const response = await client
    .query(listPaymentsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: { bills_payments: { bill_id: { _eq: id } } },
      // Hack: have to override the type. See known issues in README
    } as ListPaymentsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.payments;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const createBillMutation = graphql(/* GraphQL */ `
  mutation createBill($object: bills_insert_input!) {
    insert_bills_one(object: $object) {
      id
    }
  }
`);

export function useCreateBill() {
  return useMutation(createBillMutation);
}

export async function createBill(data: Bills_Insert_Input) {
  const response = await client
    .mutation(createBillMutation, {
      object: data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_bills_one;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const deleteBillMutation = graphql(/* GraphQL */ `
  mutation deleteBill($id: Int!) {
    update_bills_by_pk(pk_columns: { id: $id }, _set: { is_deleted: true }) {
      id
    }
    delete_bills_by_pk(id: $id) {
      id
    }
  }
`);

export async function deleteBill(id: number) {
  const response = await client
    .mutation(deleteBillMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_bills_by_pk?.id;
  if (!result) {
    throw new Error("Bill not found");
  }

  return result;
}

export const getBillAmountAndPaymentsQuery = graphql(/* GraphQL */ `
  query getBillAmountAndPayments($id: Int!) {
    bills_by_pk(id: $id) {
      amount_w_vat
    }
    payments_aggregate(where: { bills_payments: { bill_id: { _eq: $id } } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`);

export async function getBillRemainingPayment(
  id: MaybeRef<number>
): Promise<number> {
  const response = await client
    .query(getBillAmountAndPaymentsQuery, {
      // Hack: have to override the type. See known issues in README
      id: id as number,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  if (!response.data) {
    throw new Error("No data received");
  }
  const billData = response.data.bills_by_pk;
  if (!billData) {
    throw new Error("The bill not found");
  }
  const sum = response.data.payments_aggregate.aggregate?.sum?.amount;

  // If sum is null it means that no payment has been made yet
  if (!sum) return billData.amount_w_vat;

  return billData.amount_w_vat - sum;
}

export const getBillsRemainingPaymentQuery = graphql(/* GraphQL */ `
  query getBillsRemainingPayment($ids: [Int!]!) {
    remaining_amount: bills_remaining_amount(where: { id: { _in: $ids } }) {
      id
      remaining
      currency
    }
  }
`);

export function useGetBillsRemainingPayment(
  ids: MaybeRef<number | number[]>,
  pause?: MaybeRef<boolean>
) {
  return useQuery({
    query: getBillsRemainingPaymentQuery,
    variables: {
      ids: ids as number | number[],
    },
    pause,
    context: {
      requestPolicy: "cache-first",
    },
  });
}

export async function getBillsRemainingPayment(
  ids: MaybeRef<number | number[]>
) {
  const response = await client
    .query(
      getBillsRemainingPaymentQuery,
      {
        // Hack: have to override the type. See known issues in README
        ids: ids as number | number[],
      },
      {
        requestPolicy: "cache-first",
      }
    )
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.remaining_amount;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}
