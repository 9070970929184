import {
  computed,
  isRef,
  unref,
  type ComputedRef,
  type MaybeRef,
  type UnwrapRef,
} from "vue";

export type IncludesProp<T, Prefix extends string = "include"> = {
  [Key in keyof T as Key extends `${Prefix}${infer Rest}`
    ? Uncapitalize<Rest>
    : never]?: boolean;
};

export type IncludesExpanded<
  T = Record<string, boolean | undefined>,
  Prefix extends string = "include"
> = {
  [Key in keyof T as Key extends string
    ? `${Prefix}${Capitalize<Key>}`
    : never]: ComputedRef<T[Key] extends boolean ? boolean : false>;
};
export type IncludesExpandedStatic<
  T = Record<string, boolean | undefined>,
  Prefix extends string = "include"
> = {
  [Key in keyof T as Key extends string
    ? `${Prefix}${Capitalize<Key>}`
    : never]: T[Key] extends boolean ? boolean : false;
};

export function expandIncludes<
  T extends MaybeRef<Record<string, boolean | undefined>>,
  P extends string = "include"
>(includes: T, prefix: P = "include" as P) {
  if (isRef(includes)) {
    return Object.fromEntries(
      Object.keys(includes.value).map((key) => [
        `${prefix}${key.charAt(0).toUpperCase() + key.slice(1)}`,
        computed(() => includes.value[key] ?? false),
      ])
    ) as IncludesExpanded<UnwrapRef<T>, P>;
  } else {
    return Object.fromEntries(
      Object.keys(includes).map((key) => [
        `${prefix}${key.charAt(0).toUpperCase() + key.slice(1)}`,
        includes[key] ?? false,
      ])
    ) as IncludesExpandedStatic<T, P>;
  }
}

export type IncludesExpandedRaw<
  T = Record<string, boolean | undefined>,
  Prefix extends string = "include"
> = {
  [Key in keyof T as Key extends string
    ? `${Prefix}${Capitalize<Key>}`
    : never]: T[Key] extends boolean ? boolean : false;
};
export function expandIncludesRaw<
  T extends MaybeRef<Record<string, boolean | undefined>>,
  P extends string = "include"
>(includes: T, prefix: P = "include" as P) {
  return Object.fromEntries(
    Object.keys(unref(includes)).map((key) => [
      `${prefix}${key.charAt(0).toUpperCase() + key.slice(1)}`,
      unref(includes)[key] ?? false,
    ])
  ) as IncludesExpandedRaw<UnwrapRef<T>, P>;
}
