import config from "@/config";
import { client } from "@/sdk";
import * as Sentry from "@sentry/vue";
import { createHead } from "@unhead/vue";
import urql from "@urql/vue";
import { Settings } from "luxon";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router";
import "./style.css";

const app = createApp(App);

Settings.defaultLocale = "en-US";

if (config.SENTRY_ENABLE) {
  if (config.SENTRY_DNS) {
    Sentry.init({
      app: app,
      release: `eos.besh.frontend@${import.meta.env.VITE_APP_VERSION}`,
      dsn: config.SENTRY_DNS,
      integrations: [
        Sentry.browserTracingIntegration({ router: Router }),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", config.API_DOMAIN],
      // Session Replay
      replaysSessionSampleRate: config.PROD ? 0.1 : 1.0, // This sets the sample rate at 100% while in development and 10% while in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    console.warn(
      "Cannot initialize Sentry without DNS. Set 'VITE_SENTRY_DNS' in your .env file or set 'VITE_SENTRY_ENABLE' to `false` to suppress this warning."
    );
  }
}

app.use(Router);
app.use(createPinia());
app.use(createHead());
app.use(urql, client);

app.mount("#app");

console.log(`Besh ${config.APP_VERSION} <${config.MODE} mode>`);
