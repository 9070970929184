import { Int_Comparison_Exp } from "@/sdk/types";
import { DateTime } from "luxon";

export type WhereYearPeriod = {
  period?: Int_Comparison_Exp;
  year?: Int_Comparison_Exp;
};

/**
 * @summary
 * Build where statement that selects all rows in given time range.
 *
 * @description
 * The motivation for this utility is the fact that we store `year` and `month` (aka `period`) independently.
 * This makes selection within a time range not a trivial task, because one filed is ambiguous.
 *
 * This utility builds `_or` clause that explicitly selects rows in a given time range.
 *
 * @example
 * // Within a single year
 * intervalToYearPeriodWhere(
 *   DateTime.fromObject({ year: 2024, month: 2 }),
 *   DateTime.fromObject({ year: 2024, month: 5 })
 * ) // => { _or: [{ year: { _eq: 2024 }, period: { _gte: 2, _lte: 5 } }] }
 *
 * @example
 * // Different years
 * intervalToYearPeriodWhere(
 *   DateTime.fromObject({ year: 2023, month: 11 }),
 *   DateTime.fromObject({ year: 2024, month: 3 })
 * ) // => {
 *   //   _or: [
 *   //     { year: { _eq: 2023 }, period: { _gte: 11 } },
 *   //     { year: { _eq: 2024 }, period: { _lte: 3 } },
 *   //   ],
 *   // }
 *
 * @example
 * // Containing a full year
 * intervalToYearPeriodWhere(
 *   DateTime.fromObject({ year: 2022, month: 11 }),
 *   DateTime.fromObject({ year: 2025, month: 3 })
 * ) // => {
 *   //   _or: [
 *   //     { year: { _eq: 2022 }, period: { _gte: 11 } },
 *   //     { year: { _eq: 2023 } },
 *   //     { year: { _eq: 2024 } },
 *   //     { year: { _eq: 2025 }, period: { _lte: 3 } },
 *   //   ],
 *   // }
 */
export default function intervalToYearPeriodWhere(
  start: DateTime,
  end: DateTime
) {
  if (start > end)
    throw new Error("Why the start greater then the end, huh???");

  const _or: WhereYearPeriod[] = [];

  if (start.year != end.year) {
    // Add months from start year
    _or.push({
      year: { _eq: start.year },
      period: { _gte: start.month },
    });

    // Add full years in between if any
    for (let i = start.year + 1; i < end.year; i++) {
      _or.push({ year: { _eq: i } });
    }

    // Add months from end year
    _or.push({
      year: { _eq: end.year },
      period: { _lte: end.month },
    });
  } else {
    _or.push({
      year: { _eq: start.year },
      period: {
        _gte: start.month,
        _lte: end.month,
      },
    });
  }

  return { _or };
}
