import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type { ListUsersQueryVariables, Users_Set_Input } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listUsersQuery = graphql(/* GraphQL */ `
  query listUsers(
    $where: users_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [users_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = false
    $includeFirstName: Boolean = false
    $includeLastName: Boolean = false
    $includeAdditionalName: Boolean = false
    $includeUsername: Boolean = false
    $includeAvatarFileId: Boolean = false
    $includeIsActive: Boolean = false
  ) {
    users_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    users(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id @include(if: $includeId)
      first_name @include(if: $includeFirstName)
      last_name @include(if: $includeLastName)
      additional_name @include(if: $includeAdditionalName)
      username @include(if: $includeUsername)
      avatar_file_id @include(if: $includeAvatarFileId)
      is_active @include(if: $includeIsActive)
    }
  }
`);

export type ListUsersIncludesValue = IncludesProp<ListUsersQueryVariables>;

export interface ListUsersVariables {
  limit?: MaybeRef<ListUsersQueryVariables["limit"]>;
  offset?: MaybeRef<ListUsersQueryVariables["offset"]>;
  order_by?: MaybeRef<ListUsersQueryVariables["order_by"]>;
  where?: MaybeRef<ListUsersQueryVariables["where"]>;
  includes: MaybeRef<ListUsersIncludesValue>;
}

export function useListUsers(props: ListUsersVariables) {
  return useQuery({
    query: listUsersQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListUsersQueryVariables,
  });
}

export async function listUsers(props: ListUsersVariables) {
  const response = await client
    .query(listUsersQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListUsersQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.users;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getUsersQuery = graphql(/* GraphQL */ `
  query getUsers {
    users {
      id
      first_name
      last_name
      additional_name
      username
      avatar_file_id
      is_active
    }
  }
`);

export function useGetUsers() {
  return useQuery({
    query: getUsersQuery,
  });
}

export async function getUsers() {
  const response = await client.query(getUsersQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.users;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getUserQuery = graphql(/* GraphQL */ `
  query getUser($userId: uuid!) {
    users_by_pk(id: $userId) {
      first_name
      last_name
      additional_name
      id
      username
      avatar_file_id
      is_active
    }
  }
`);

export function useGetUser(userId: string) {
  return useQuery({
    query: getUserQuery,
    variables: {
      userId,
    },
  });
}

export async function getUser(userId: string) {
  const response = await client.query(getUserQuery, { userId }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.users_by_pk;
  return data ?? null;
}

export const updateUserMutation = graphql(/* GraphQL */ `
  mutation updateUser($userId: uuid!, $data: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $userId }, _set: $data) {
      first_name
      last_name
      additional_name
      id
      username
      avatar_file_id
      is_active
    }
  }
`);

export async function updateUser(userId: string, data: Users_Set_Input) {
  const response = await client
    .mutation(updateUserMutation, { userId, data })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.update_users_by_pk;
  if (!result) {
    throw new Error("User not found");
  }
  return result;
}

export const createUserMutation = graphql(/* GraphQL */ `
  mutation createUser($object: users_insert_input!) {
    insert_users_one(object: $object) {
      accounts {
				id
      }
      first_name
      last_name
      additional_name
      id
      username
      avatar_file_id
      is_active
    }
  }
`);

export const setUserActiveMutation = graphql(/* GraphQL */ `
  mutation setUserActive($id: uuid!, $is_active: Boolean!) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { is_active: $is_active }
    ) {
      id
      first_name
      last_name
      additional_name
      username
      avatar_file_id
      is_active
    }
  }
`);

export async function setUserActive(id: string, is_active: boolean) {
  const response = await client
    .mutation(setUserActiveMutation, { id, is_active })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_users_by_pk;
  if (!data) {
    throw new Error("User not found");
  }

  return data;
}

export const changeUserPasswordMutation = graphql(/* GraphQL */ `
  mutation changeUserPassword($password: String!, $user_id: String!) {
    admin_change_password(password: $password, user_id: $user_id) {
      id
    }
  }
`);

export async function changeUserPassword(
  user_id: string,
  password: string
): Promise<void> {
  const { error } = await client
    .mutation(changeUserPasswordMutation, {
      user_id,
      password,
    })
    .toPromise();

  if (error) {
    throw new Error(error?.message);
  }
}
