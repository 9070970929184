import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";

export const getNotificationsQuery = graphql(/* GraphQL */ `
  query getNotifications {
    notifications(order_by: { created_at: desc }) {
      body
      created_at
      id
      is_read
      ref_id
      title
      type
      user_id
    }
  }
`);

export function useGetNotifications() {
  return useQuery({
    query: getNotificationsQuery,
  });
}

export async function getNotifications() {
  const response = await client.query(getNotificationsQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.notifications;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const readOneNotificationMutation = graphql(/* GraphQL */ `
  mutation readOneNotification($id: uuid!) {
    update_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { is_read: true }
    ) {
      id
      is_read
      read_at
    }
  }
`);

export async function readOneNotification(id: string) {
  const response = await client
    .mutation(readOneNotificationMutation, { id })
    .toPromise();
  if (response.error) {
    throw response.error;
  }

  const data = response.data?.update_notifications_by_pk;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}
