export const RootNamespace = "besh";

/**
 * @example
 * getNamespacedKey("my_key"); // => "root.my_key"
 * @example
 * getNamespacedKey("nested", "my_key"); // => "root.nested.my_key"
 */
export default function getNamespacedKey(...key_chain: string[]) {
  return [RootNamespace, ...key_chain].join(".");
}
