import config from "@/config";
import { accessToken } from "@/stores/auth-tokens";
import axios, { AxiosError } from "axios";

export const REST_API_URL = config.API_URL_REST;

const http = axios.create({
  baseURL: REST_API_URL,
});

http.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  null,
  {
    synchronous: true,
    runWhen: () => accessToken != null,
  }
);

export function isAxiosError(error: AxiosError | unknown): error is AxiosError {
  return (error as AxiosError).name == "AxiosError";
}

// TODO: Would be good to add access refresh here too.

export default http;
