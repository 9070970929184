import { useMutation } from "@urql/vue";
import { graphql } from "../gql";
import { client } from "..";
import createErrorWithCode from "@/utils/error-with-code";

export const AuthInvalidCredentialsError = createErrorWithCode(
  "invalid_credentials",
  "Login faild due to invalid credentials"
);

export const loginMutation = graphql(/* GraphQL */ `
  mutation login($username: String!, $password: String!) {
    users_login(password: $password, username: $username) {
      access_token
      expires_in
      refresh_token
    }
  }
`);

export function useLogin() {
  return useMutation(loginMutation);
}

export async function login(username: string, password: string) {
  const { data, error } = await client
    .mutation(loginMutation, { username, password })
    .toPromise();
  if (error) {
    if (
      error.graphQLErrors.some((e) => e.extensions.code == "InvalidCredentials")
    ) {
      throw new AuthInvalidCredentialsError();
    } else {
      throw error;
    }
  }

  const tokens = data?.users_login;
  if (!tokens) {
    throw new Error("No data received");
  }

  return {
    accessToken: tokens.access_token,
    expiresIn: tokens.expires_in,
    refreshToken: tokens.refresh_token,
  };
}


export const switchAccountMutation = graphql(/* GraphQL */ `
  mutation switchAccount($account_id: uuid!) {
    accounts_switch(account_id: $account_id) {
      access_token
      expires_in
      refresh_token
    }
  }
`);

export function useSwitchAccount() {
  return useMutation(loginMutation);
}

export async function switchAccount(account_id: string) {
  const { data, error } = await client
    .mutation(switchAccountMutation, { account_id })
    .toPromise();
  if (error) {
    throw new Error(error.message);
  }

  const tokens = data?.accounts_switch;
  if (!tokens) {
    throw new Error("No data received");
  }

  return {
    accessToken: tokens.access_token,
    expiresIn: tokens.expires_in,
    refreshToken: tokens.refresh_token,
  };
}

export const refreshAccessMutation = graphql(/* GraphQL */ `
  mutation refreshAccess($refresh_token: String!) {
    users_refresh(refresh_token: $refresh_token) {
      access_token
      expires_in
      refresh_token
    }
  }
`);

export function useRefreshAccess() {
  return useMutation(refreshAccessMutation);
}

export async function refreshAccess(refresh_token: string) {
  const { data, error } = await client
    .mutation(refreshAccessMutation, { refresh_token })
    .toPromise();
  if (error) {
    throw new Error(error.message);
  }

  const tokens = data?.users_refresh;
  if (!tokens) {
    throw new Error("No data received");
  }

  return {
    accessToken: tokens.access_token,
    expiresIn: tokens.expires_in,
    refreshToken: tokens.refresh_token,
  };
}
