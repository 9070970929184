import { diff } from "@/utils/set-diff";
import { useMutation, useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type { ListRolesQueryVariables } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listRolesQuery = graphql(/* GraphQL */ `
  query listRoles(
    $where: roles_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [roles_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = false
    $includeScopesAggregate: Boolean = false
    $includeScopes: Boolean = false
  ) {
    roles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    roles(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id @include(if: $includeId)
      name @include(if: $includeName)
      scopes @include(if: $includeScopes) {
        scope
      }
      scopes_aggregate @include(if: $includeScopesAggregate) {
        aggregate {
          count
        }
      }
    }
  }
`);

export type ListRolesIncludesValue = IncludesProp<ListRolesQueryVariables>;

export interface ListRolesVariables {
  limit?: MaybeRef<ListRolesQueryVariables["limit"]>;
  offset?: MaybeRef<ListRolesQueryVariables["offset"]>;
  order_by?: MaybeRef<ListRolesQueryVariables["order_by"]>;
  where?: MaybeRef<ListRolesQueryVariables["where"]>;
  includes: MaybeRef<ListRolesIncludesValue>;
}

export function useListRoles(props: ListRolesVariables) {
  return useQuery({
    query: listRolesQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListRolesQueryVariables,
  });
}

export async function listRoles(props: ListRolesVariables) {
  const response = await client
    .query(listRolesQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListRolesQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.roles;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getRolesQuery = graphql(/* GraphQL */ `
  query getRoles {
    roles {
      id
      name
      scopes {
        scope
      }
    }
  }
`);

export function useGetRoles() {
  return useQuery({
    query: getRolesQuery,
  });
}

export async function getRoles() {
  const response = await client.query(getRolesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.roles;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getRoleQuery = graphql(/* GraphQL */ `
  query getRole($id: uuid!) {
    roles_by_pk(id: $id) {
      id
      name
      scopes {
        scope
      }
    }
  }
`);

export function useGetRole(id: MaybeRef<string>) {
  return useQuery({
    query: getRoleQuery,
    variables: {
      id: id as string,
    },
  });
}

export async function getRole(id: MaybeRef<string>) {
  const response = await client.query(getRoleQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.roles_by_pk;
  return data;
}

export const getRoleScopesQuery = graphql(/* GraphQL */ `
  query getRoleScopes($id: uuid!) {
    roles_scopes(where: { role_id: { _eq: $id } }) {
      id
      scope
    }
  }
`);

export async function getRoleScopes(id: string) {
  const response = await client
    .query(getRoleScopesQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.roles_scopes ?? [];
}

export const createRoleMutation = graphql(/* GraphQL */ `
  mutation createRole($name: String!, $scopes: [roles_scopes_insert_input!]!) {
    insert_roles_one(object: { name: $name, scopes: { data: $scopes } }) {
      id
    }
  }
`);

export function useCreateRole() {
  return useMutation(createRoleMutation);
}

export async function createRole(name: string, scopes: string[]) {
  const response = await client
    .mutation(createRoleMutation, {
      name,
      scopes: scopes.map((v) => ({ scope: v })),
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.insert_roles_one?.id;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

/** Can be used to update role name or replace scopes, or both */
export async function updateRole(
  id: string,
  data: { name?: string; scopes?: string[] }
) {
  const requests: Array<Promise<unknown>> = [];
  if (data.name) {
    requests.push(updateRoleName(id, data.name));
  }
  if (data.scopes) {
    requests.push(updateRoleScopes(id, data.scopes));
  }
  await Promise.all(requests);

  return true;
}

export const updateRoleNameMutation = graphql(/* GraphQL */ `
  mutation updateRoleName($id: uuid!, $name: String!) {
    update_roles_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
    }
  }
`);

export function useUpdateRoleName() {
  return useMutation(updateRoleNameMutation);
}

/** Updates the role name */
export async function updateRoleName(id: string, name: string) {
  const response = await client
    .mutation(updateRoleNameMutation, {
      id,
      name,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.update_roles_by_pk?.id;
  if (!data) {
    throw new Error("The role not found");
  }

  return data;
}

export const updateRoleScopesMutation = graphql(/* GraphQL */ `
  mutation updateRoleScopes(
    $add: [roles_scopes_insert_input!]!
    $remove: [uuid!]!
  ) {
    delete_roles_scopes(where: { id: { _in: $remove } }) {
      affected_rows
    }
    insert_roles_scopes(objects: $add) {
      affected_rows
    }
  }
`);

export async function updateRoleScopes(roleId: string, scopes: string[]) {
  const currentScopes = await getRoleScopes(roleId);

  const oldScopesSet = new Set(currentScopes.map(({ scope }) => scope));
  const newScopesSet = new Set(scopes);

  const scopesToDelete = diff(oldScopesSet, newScopesSet);
  const scopesToAdd = diff(newScopesSet, oldScopesSet);

  const response = await client
    .mutation(updateRoleScopesMutation, {
      add: Array.from(scopesToAdd, (scope) => ({
        role_id: roleId,
        scope,
      })),
      remove: currentScopes
        .filter(({ scope }) => scopesToDelete.has(scope))
        .map(({ id }) => id),
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }
}

export const deleteRoleMutation = graphql(/* GraphQL */ `
  mutation deleteRole($id: uuid!) {
    delete_roles_scopes(where: { role_id: { _eq: $id } }) {
      affected_rows
    }
    delete_roles_by_pk(id: $id) {
      id
    }
  }
`);

export function useDeleteRole() {
  return useMutation(deleteRoleMutation);
}

/** Delete role and it's scopes */
export async function deleteRole(id: string) {
  const response = await client
    .mutation(deleteRoleMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.delete_roles_by_pk?.id;
  return data != undefined;
}
